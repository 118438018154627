<template>
   <div class="fcc-advisory">
      <p>
         In our best effort to keep you informed we want to share with
         you the advisory released by the FCC regarding prerecorded and
         autodialed calls for political purposes.
      </p>
      <a href="/files/FCC%20Enforcement%20Advisory%20Political%20Campaigns%20and%20Promoters.pdf">
         To read the advisory click here.
      </a>
   </div>
</template>

<style scoped>
.fcc-advisory
{
   background-color: var(--bs-body-bg);
   padding: 1em;
   border-radius: 0.1em;
   font-size: 0.8em;
}

/* Medium and up */
/*@media screen and (min-width: 768px) {}*/

/* Medium only */
/*@media screen and (min-width: 768px) and (max-width: 991px) {}*/

/* Large and up */
/*@media screen and (min-width: 992px) {}*/

/* Large only */
/*@media screen and (min-width: 992px) and (max-width: 1199px) {}*/

/* X-Large and up */
/*@media screen and (min-width: 1200px) {}*/
</style>
