<template>
   <div class="home">
      <div class="home__main">
         <vue-bootstrap-blocking-notifications></vue-bootstrap-blocking-notifications>

         <div v-if="!blocking_notification_shown">
            <sign-in-form v-if="view == 'login'"></sign-in-form>
            <oops-form v-if="view == 'oops'" @verify="view = 'login'"></oops-form>
         </div>
      </div>
   </div>
</template>

<script>
// @ is an alias to /src
'use strict';
import {mapActions} from 'vuex';
import {mapGetters} from 'vuex';
import SignInForm from '@/components/sign-in-form';
import OopsForm from '@/components/oops-form';
// import util from '@/util';


export default {
   emits: [],
   name: 'default',

   components: {
      OopsForm,
      SignInForm,
   },// /components{}

   data (){
      return {
         view: '',
      };
   },// /data()

   computed:{
      ...mapGetters([
         'reason_error_message',
      ]),
   }, // /computed{}

   methods: {

      ...mapActions([
         'set_page_title',
      ]),
   },// methods{}

   mounted (){
      this.set_page_title( '' );

      if( this.reason_error_message ){
         return this.view = 'oops';
      }

      this.view = 'login';
   },// /mounted()
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .home
{
   max-width: 30em;
   margin: auto;
} */

/* Small only */
/* @media screen and (max-width: 767px) {} */

/* Medium and up */
/*@media screen and (min-width: 768px) {}*/

/* Medium only */
/*@media screen and (min-width: 768px) and (max-width: 991px) {}*/

/* Large and up */
/*@media screen and (min-width: 992px) {}*/

/* Large only */
/*@media screen and (min-width: 992px) and (max-width: 1199px) {}*/

/* X-Large and up */
/*@media screen and (min-width: 1200px) {}*/
</style>
