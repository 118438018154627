<template>
   <div class="sign-in-form">
      <div class="sign-in-form__main">
         <webstyle-logo class="sign-in-form__logo"></webstyle-logo>
         <vue-bootstrap-blocking-notifications></vue-bootstrap-blocking-notifications>

         <div v-if="!blocking_notification_shown && login">
            <p class="text-center">Sign in to your account</p>

            <form @submit.prevent="on_form_submit">
               <div class="form-group">
                  <div class="input-group">
                  <span class="input-group-text" id="input_username">
                     <i class="fas fa-user"></i>
                  </span>
                  <input type="text" class="form-control" placeholder="Username"
                     aria-label="Username" aria-describedby="input_username"
                     autocomplete="off"
                     autofocus="autofocus" ref="autofocus"
                     v-model="login.username" />
                  </div>
               </div>
               <div class="form-group">
                  <div class="input-group">
                  <span class="input-group-text" id="input_password">
                     <i class="fas fa-lock"></i>
                  </span>
                  <input type="password" class="form-control" placeholder="Password"
                     aria-label="Password" aria-describedby="input_password"
                     v-if="!show_password"
                     v-model="login.password" />
                  <input type="text" class="form-control" placeholder="Password"
                     aria-label="Password" aria-describedby="input_password"
                     v-if="show_password"
                     v-model="login.password" />
                     <button class="btn btn-outline-secondary" type="button"
                        title="Toggle password visibility."
                        @click="show_password = !show_password">
                        <i class="fas fa-eye" v-if="show_password"></i>
                        <i class="fas fa-eye-slash" v-if="!show_password"></i>
                     </button>
                  </div>
               </div>

               <p class="">
                  <router-link to="/forgot_password">
                     Forgot username or password?
                  </router-link>
               </p>

               <div class="sign-in-form__btns">
                  <button class="sign-in-form__btn btn btn-primary" type="submit">
                     <i class="fa-solid fa-unlock"></i>
                     Sign In
                  </button>
               </div>
            </form>
         </div>
      </div>
      <fcc-advisory v-if="enable_fcc_advisory"></fcc-advisory>
   </div>
</template>

<script>
// @ is an alias to /src
'use strict';
import {mapActions} from 'vuex';
import {mapGetters} from 'vuex';
import Login from '@/lib/login';
import FccAdvisory from '@/components/fcc-advisory';
import WebstyleLogo from '@/components/webstyle-logo';
import util from '@/util';


export default {
   emits: [],

   components: {
      FccAdvisory,
      WebstyleLogo,
   },// /components{}

   data (){
      return {
         login: null,
         show_password: false,
      };
   },// /data()

   computed:{
      ...mapGetters([
         'enable_fcc_advisory',
      ]),
   }, // /computed{}

   methods: {
      async on_form_submit(){
         this.blocking_notification_show( 'wait', 'Logging you in...' );
         try{
            await this.login.send();
            console.log( 'this.login.result', util.clone( this.login.result ) );
            const n_client_id = this.login.result.clientid;
            
            this.blocking_notification_show( 'wait', 'Almost there...' );
            setTimeout( () => {
               if(  this.login.result.multiclient == true ){
                 return window.location.assign( `/${n_client_id}/loginselector.wr` );
               }
               window.location.assign( `/${n_client_id}/clientresources.wr` );
            }, 2000 );
         }
         catch( err ){
            this.blocking_notification_show( 'problem', err.message );
         }
      },// /on_form_submit()

      ...mapActions([
         'set_page_title',
      ]),
   },// methods{}

   mounted (){
      this.set_page_title( '' );
      this.login = new Login();

      /** 
       * Chrome will not autofocus and give an error like...
       * "Autofocus processing was blocked because a document's URL has a fragment '#/..'."
       * So we need to programmatically make sure this field is set focus to.
       */
      this.$refs.autofocus && this.$refs.autofocus.focus();
   },// /mounted()
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sign-in-form
{
   max-width: 30em;
   margin: auto;
}

.sign-in-form__main
{
   background-color: var(--bs-body-bg);
   padding: 0.5em;
   border-radius: 0.1em;
   margin-bottom: 2em;
}

.sign-in-form__logo
{
   margin-bottom: 1em;
}

.form-group
{
   margin-bottom: 1em;
}

.sign-in-form__btns
{
   display: flex;
   justify-content: flex-end;
}

/* Small only */
@media screen and (max-width: 767px) 
{
   .sign-in-form__btn
   {
      flex-grow: 1;
   }
}

/* Medium and up */
/*@media screen and (min-width: 768px) {}*/

/* Medium only */
/*@media screen and (min-width: 768px) and (max-width: 991px) {}*/

/* Large and up */
/*@media screen and (min-width: 992px) {}*/

/* Large only */
/*@media screen and (min-width: 992px) and (max-width: 1199px) {}*/

/* X-Large and up */
/*@media screen and (min-width: 1200px) {}*/
</style>
