<template>
   <div class="oops">
      <div class="oops__main">
         <webstyle-logo class="oops__logo"></webstyle-logo>
         <vue-bootstrap-blocking-notifications></vue-bootstrap-blocking-notifications>

         <div v-if="!blocking_notification_shown">
            <h1>Oops!</h1>
            <div class="alert alert-danger">
               <i class="fas fa-triangle-exclamation"></i>
               {{reason_error_message}}
            </div>

            <div class="d-grid gap-2">
               <button class="btn btn-outline-primary btn-lg" type="button"
                  v-if="window_history.length > 1"
                  @click="go_back"
                  title="Click here to go back to the previous page.">
                  <i class="fas fa-arrow-left"></i>
                  Go back
               </button>
               <button class="btn btn-primary btn-lg" type="button"
                  title="Click here to verify your permissions."
                  @click="on_verify_click">
                  Verify now
               </button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
// @ is an alias to /src
'use strict';
import {mapActions} from 'vuex';
import {mapGetters} from 'vuex';
import WebstyleLogo from '@/components/webstyle-logo';
// import util from '@/util';


export default {
   emits: [ 'verify'],

   components: {
      WebstyleLogo,
   },// /components{}

   data (){
      return {
         view: '',
      };
   },// /data()

   computed:{
      ...mapGetters([
         'reason_error_message',
         'window_history',
      ]),
   }, // /computed{}

   methods: {
      go_back(){
         window.history.back();
      },// /go_back()

      on_verify_click(){
        this.$emit( 'verify' );
      },// /on_verify_click()

      ...mapActions([
         'set_page_title',
      ]),
   },// methods{}

   mounted (){
      this.set_page_title( '' );

      /** 
       * Chrome will not autofocus and give an error like...
       * "Autofocus processing was blocked because a document's URL has a fragment '#/..'."
       * So we need to programmatically make sure this field is set focus to.
       */
      this.$refs.autofocus && this.$refs.autofocus.focus();
   },// /mounted()
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.oops
{
   max-width: 30em;
   margin: auto;
}

.oops__main
{
   background-color: var(--bs-body-bg);
   padding: 0.5em;
   border-radius: 0.1em;
   padding-bottom: 2em;
}

.oops__logo
{
   margin-bottom: 1em;
}

/* Small only */
/* @media screen and (max-width: 767px) {} */

/* Medium and up */
/*@media screen and (min-width: 768px) {}*/

/* Medium only */
/*@media screen and (min-width: 768px) and (max-width: 991px) {}*/

/* Large and up */
/*@media screen and (min-width: 992px) {}*/

/* Large only */
/*@media screen and (min-width: 992px) and (max-width: 1199px) {}*/

/* X-Large and up */
/*@media screen and (min-width: 1200px) {}*/
</style>
